.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 15px;
  right: 21px;
  top: 20px;
}

.bm-burger-bars {
  background: #3be529;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #3be529;
}

.bm-menu {
  background: #0e0e0e;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-item-list {
  padding: 0.8em;
}
.bm-item:first-child {
  align-items: center;
  margin-top: 50px;
}

.bm-item img {
  margin-right: 10px;
}

.bm-item:last-child {
  margin-top: 50px;
}

.bm-item {
  display: block;
  color: #fff;
  text-align: center;
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 30px;
}
